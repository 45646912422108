export const colors = {
  blue: '#3eb0ef',
  green: '#a4d037',
  purple: '#ad26b4',
  yellow: '#fecd35',
  red: '#f05230',
  darkgrey: '#15171A',
  midgrey: '#738a94',
  lightgrey: '#c5d2d9',
  whitegrey: '#e5eff5',
  pink: '#fa3a57',
  brown: '#a3821a',
  massCann: {
    lightGreen: '#2AB573',
    midGreen: '#006738',
    darkGreen: '#014222',
    midGray: '#555963',
    darkGray: '#2A2D31',
    lightGray: '#F1F1F2',
    darkBlue: '#19284B',
  },
};
